import React, { useState, useCallback } from 'react';

import { colors, mq } from '../theme';
import Fuse from 'fuse.js';
import { graphql } from 'gatsby';
import InputSuggest from '../components/InputSuggest';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import { Heading2, Paragraph, Heading3 } from '../components/Heading/Text';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import Newsletter from '../components/Newsletter';
import Link from '../components/Link';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const FoireAuxQuestionPage = ({ data }) => {
  const computeList = useCallback(
    articles =>
      Object.values(
        articles.reduce((categories, article) => {
          article.categories.forEach(category => {
            categories[category] = {
              name: category,
              articles: [].concat(
                (categories[category] || {}).articles || [],
                article
              )
            };
          });

          return categories;
        }, {})
      ),
    []
  );

  const [list] = useState(computeList(data.allFaqJson.nodes));
  const [listFilter, setListFilter] = useState([]);

  const handleSearch = text => {
    if (text.length === 0) {
      setListFilter([]);
      return;
    }

    const options = {
      shouldSort: true,
      threshold: 0.3,
      keys: ['tags', 'title']
    };

    const fuse = new Fuse(data.allFaqJson.nodes, options);
    setListFilter(fuse.search(text));
  };

  return (
    <Layout>
      <SEO
        title="Foire aux questions"
        description="Vous êtes client Web2vi ou en période de découverte? Trouvez toutes les réponses aux questions les plus posées quant à l'utilisation de notre logiciel. "
      />

      <Container
        background={colors.neutralWhite}
        css={mq({
          zIndex: 1,
          paddingBottom: [0, 0],
          paddingTop: 0,
          height: [550, 400, 500],
          position: 'relative',
          alignItems: 'center',
          justifyContent: ['inherit', 'center']
        })}
      >
        <Container.ContentLeft size={70}>
          <Heading2 primary css={mq({ marginTop: [60, 0, 20] })} light>
            Foire aux <span>questions</span>
          </Heading2>
          <Paragraph css={{ marginTop: 30 }}>
            Découvrez ici les réponses aux questions les plus fréquentes
            <br />
            concernant le logiciel{' '}
            <Link decoration="true" to="/">
              Web2vi.com
            </Link>
          </Paragraph>

          <InputSuggest onSearch={handleSearch}>
            {listFilter !== [] &&
              listFilter.map(article => (
                <InputSuggest.Item key={article.id} href={article.url}>
                  {article.title}
                </InputSuggest.Item>
              ))}
          </InputSuggest>
        </Container.ContentLeft>
        <div
          css={mq({
            display: ['block', 'none', 'none'],
            position: ['absolute', 'relative'],
            bottom: 0,
            right: [-120, 0],
            width: 800,
            height: [550, 500, 400]
          })}
        >
          <BackgroundImage
            src="simon.png"
            css={mq({
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center'
            })}
          />
        </div>
      </Container>

      <Container background={colors.neutralDarker}>
        <Container.ContentLeft size={100}>
          {list.map((category, i) => (
            <div key={`${category.id}-${i}`}>
              <Heading3 dangerouslySetInnerHTML={{ __html: category.name }} />
              <ul
                css={{
                  marginBottom: 60,
                  listStyleType: 'none',
                  padding: 0,
                  border: `1px solid #646464`
                }}
              >
                {category.articles.map(article => (
                  <li
                    key={article.id}
                    css={{
                      color: colors.neutralWhite,
                      padding: 10,
                      borderBottom: `1px solid #646464`,
                      '&:last-child': {
                        borderBottom: 0
                      }
                    }}
                  >
                    <Paragraph
                      css={{
                        marginBottom: 0
                      }}
                    >
                      <a
                        css={{ color: 'inherit' }}
                        alt={article.title}
                        href={article.url}
                      >
                        {article.title}
                      </a>
                    </Paragraph>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Container.ContentLeft>
      </Container>
      <Newsletter />
      <FooterBanner />
    </Layout>
  );
};

export const query = graphql`
  query FoireAuxQuestionQuery {
    allFaqJson {
      nodes {
        id
        categories
        title
        url
        tags
      }
    }
  }
`;

export default FoireAuxQuestionPage;
