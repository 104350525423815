import React, { useCallback } from 'react';
import {
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  StackDivider,
  Link
} from '..';
import { SearchIcon } from '../Icons/SearchIcon.js';

const InputSuggest = ({ children, onSearch, ...rest }) => {
  const handleSearch = useCallback(
    ({ target: { value } }) => onSearch(value),
    [onSearch]
  );
  return (
    <Box>
      <InputGroup size="lg" zIndex={1}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon boxSize={5} color="gray.500" />
        </InputLeftElement>
        <Input
          onChange={handleSearch}
          placeholder="Quel est votre besoin?"
          {...rest}
        />
      </InputGroup>
      <Box position="relative">
        {!!children.length && (
          <Stack
            width="100%"
            backgroundColor="white"
            boxShadow="lg"
            position="absolute"
            top={2}
            zIndex={1}
            rounded="md"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={0}
          >
            {children}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

const InputSuggestItem = ({ children, ...rest }) => {
  return (
    <Link display="block" px={5} py={3} isTruncated {...rest}>
      {children}
    </Link>
  );
};

InputSuggest.Item = InputSuggestItem;

export default InputSuggest;
